import { lazy, Suspense, useContext, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import { AuthContext } from '../app/auth/service/AuthProvider'
import { useAppDispatch } from '../app/shared/hooks/useReduxTHooks'
import { LazySpinner } from '../app/shared/spinner/Spinner'
import { getUserInfo, setToken } from '../store/slices/authSlice'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'

const AuthRoutes = lazy(() => import('./authentication/AuthRoutes'))
const CoursesFreePage = lazy(() => import('../app/main/courses/page/CoursesFreePage'))
const CoursesContentFreePage = lazy(
    () => import('../app/main/courses/page/CourseContentFreePage'),
)
// const LandingPage = lazy(() => import('../app/landing/page/LandingPage'))
// const LandingRoutes = lazy(() => import('./landing/LandingRoutes'))
const DashboardRoutes = lazy(() => import('./dashboard/DashboardRoutes'))
const RedirectRoutes = lazy(() => import('./redirect/RedirectRoutes'))
// const Terms = lazy(() => import('./landing/Terms'))

const AppRouter = () => {
    const {
        state: { isAuthenticated, checking },
        dispatch,
    } = useContext(AuthContext)

    const dispatchRedux = useAppDispatch()

    // const history = useHistory()

    // const redirectCourse = localStorage.getItem('redirectCourse')

    // redirectCourse && history.push(redirectCourse)

    useEffect(() => {
        const user = localStorage.getItem('user')
        const token = localStorage.getItem('token')
        // const redirectCourse = localStorage.getItem('redirectCourse')
        if (user && token) {
            dispatch({ type: 'AUTH_USER', paylaod: JSON.parse(user) })
            dispatch({ type: 'SET_CHECKING' })
            // if (redirectCourse) {
            //     console.log('REDIRECTING TO COURSE', redirectCourse)
            //     history.push(redirectCourse)
            // }
            // return history.push('/dashboard');
        } else {
            // localStorage.clear()
            dispatch({ type: 'CLEAR_USER' })
            dispatch({ type: 'SET_CHECKING' })
        }
    }, [dispatch])

    useEffect(() => {
        console.log('isAuthenticated', isAuthenticated)
        const token = localStorage.getItem('token')
        if (isAuthenticated && token) {
            dispatchRedux(getUserInfo())
            dispatchRedux(setToken(token))
        }
    }, [isAuthenticated, dispatchRedux])

    useEffect(() => {
        const isExpired = localStorage.getItem('expired')
        console.log('isExpired', isExpired)
        let timer: NodeJS.Timeout
        if (isExpired) {
            toast.warn('Sesión expirada, ingrese nuevamente')
            timer = setTimeout(() => {
                localStorage.removeItem('expired')
            }, 2000)
        }

        return () => clearInterval(timer)
    }, [])

    if (checking) return <LazySpinner />

    return (
        <Router>
            <Suspense fallback={<LazySpinner />}>
                <Switch>
                    {/* <AuthProvider> */}

                    {/* Página principal */}
                    {/* <PublicRoute exact path='/' component={LandingPage} isAuth={ isAuthenticated} /> */}

                    {/* Authentication */}
                    {/* <Route path='/auth' component={AuthRoutes} /> */}
                    <PublicRoute
                        path="/auth"
                        component={AuthRoutes}
                        isAuth={isAuthenticated}
                    />

                    <PublicRoute
                        exact
                        path="/courses"
                        component={CoursesFreePage}
                        isAuth={isAuthenticated}
                    />
                    <PublicRoute
                        exact
                        path="/courses/:idcursoprogramado/:idcurso/:typecourse"
                        component={CoursesContentFreePage}
                        isAuth={isAuthenticated}
                    />

                    {/* Dashboard */}
                    {/* <Route path='/dashboard' component={DashboardRoutes} /> */}
                    <PrivateRoute
                        path="/dashboard"
                        component={DashboardRoutes}
                        isAuth={isAuthenticated}
                    />

                    {/* Redirect Routes */}
                    {/* <Route path='/redirect' component={RedirectRoutes} /> */}
                    <PrivateRoute
                        path="/redirect"
                        component={RedirectRoutes}
                        isAuth={isAuthenticated}
                    />

                    {/* Landing Routes */}
                    {/* <PublicRoute path='/service' component={LandingRoutes}  isAuth={ isAuthenticated} />
            <PublicRoute path='/terms' component={Terms} isAuth={ isAuthenticated }/> */}
                    <Redirect to="/courses" />
                    {/* </AuthProvider> */}
                </Switch>
            </Suspense>
            <ToastContainer
                autoClose={4000}
                position="bottom-right"
                theme="colored"
                style={{ fontSize: '1rem' }}
            />
        </Router>
    )
}

export default AppRouter
